import React, { useEffect, useState } from "react";
import { Badge, Card, Col, Dropdown, Table } from "react-bootstrap";
import {
  getAllUsers,
  getConsultationPayments,
  getVirtualConsultant,
  userAction,
} from "../../services/AuthService";
import PageTitle from "../layouts/PageTitle";
import Spinner from "../components/common/Spinner";
import Pagination from "../components/common/Pagination";
import { toast, ToastContainer } from "react-toastify";
import moment from "moment";
import { useDispatch } from "react-redux";
import { clientDetailAction } from "../../store/actions/AuthActions";
import { format } from "date-fns";

export default function ConsultantPayments(props) {
  const dispatch = useDispatch();

  const svg1 = (
    <svg width="20px" height="20px" viewBox="0 0 24 24" version="1.1">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <rect x="0" y="0" width="24" height="24"></rect>
        <circle fill="#000000" cx="5" cy="12" r="2"></circle>
        <circle fill="#000000" cx="12" cy="12" r="2"></circle>
        <circle fill="#000000" cx="19" cy="12" r="2"></circle>
      </g>
    </svg>
  );
  const notifyTopRight = (success) => {
    toast.success(`✅ ${success}`, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
  };
  const notifyError = (error) => {
    toast.error(`❌${error}`, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };
  const [loader, setLoader] = useState(false);
  const [users, setUsers] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [apiError, setApiError] = useState("");
  const [search, setSearch] = useState("");
  const limit = 10;
  const [listLength, setListlenght] = useState("");

  function getTableData() {
    setLoader(true);
    getConsultationPayments(currentPage, limit)
      .then((response) => {
        setUsers(response.data.data.payments);
        const total = response.data.data.total;
        setLoader(false);
        setPageCount(Math.ceil(total / limit));
        setListlenght(response.data.data.total);
      })
      .catch((error) => {
        setLoader(false);
        if (error.response?.data?.statusCode === 401) {
          localStorage.clear("taldDetails");
          props.history.push("/login");
        }
      });
  }
  function onAction(userId, isBlocked) {
    setLoader(true);
    userAction(userId)
      .then((response) => {
        {
          isBlocked
            ? notifyTopRight("Blocked Successfully!")
            : notifyTopRight("Unblocked Successfully!");
        }

        setLoader(false);
        getTableData();
      })
      .catch((error) => {
        setLoader(false);
        notifyError(error.response?.data?.message);
        if (error.response?.data?.statusCode === 401) {
          localStorage.clear("taldDetails");
          props.history.push("/login");
        }
      });
  }

  useEffect(() => {
    getTableData();
  }, [currentPage]);
  return (
    <div>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />

      <PageTitle activeMenu="Consultant payment" motherMenu="Payment" />

      <Col>
        <Card>
          <Card.Body>
            <Table responsive>
              <thead style={{ color: "black" }}>
                <tr>
                  <th>
                    <strong>TRANSACTION ID</strong>
                  </th>
                  <th>
                    <strong>AMOUNT</strong>
                  </th>
                  <th>
                    <strong>DURATION</strong>
                  </th>
                  <th>
                    <strong>CLIENT EMAIL</strong>
                  </th>
                  <th>
                    <strong>CLIENT NAME</strong>
                  </th>
                  <th>
                    <strong>DESIGNER EMAIL</strong>
                  </th>
                  <th>
                    <strong>DATE TIME</strong>
                  </th>
                  <th>
                    <strong>PROJECT SUMMARY</strong>
                  </th>
                </tr>
              </thead>
              <tbody className="border-bottom border-secondary">
                {users?.map((item) => (
                  <tr>
                    <td>{item?.transitionId}</td>
                    <td>{item?.amount}</td>
                    <td>{item?.consultationId?.durationTime.replace('_mins', ' Minutes')}</td>
                    <td>{item?.user?.email}</td>
                    <td>{item?.user?.name}</td>
                    <td>{item?.designer?.email}</td>
                    <td>
                      {format(new Date(item?.createdAt), "yyyy-MM-dd HH:mm:ss")}
                    </td>
                    <td>{item?.consultationId?.projectSummary}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
            {users?.length === 0 && !loader ? (
              <div className="justify-content-center d-flex my-5 ">
                Sorry, Data Not Found!
              </div>
            ) : (
              ""
            )}
            {users?.length === 0 ? (
              ""
            ) : (
              <div className="">
                <div className="d-flex justify-content-between align-items-center">
                  <div className="dataTables_info">
                    Showing {currentPage * limit + 1} to{" "}
                    {listLength > (currentPage + 1) * limit
                      ? (currentPage + 1) * limit
                      : listLength}{" "}
                    of {listLength} entries
                  </div>
                  <Pagination
                    pageCount={pageCount}
                    pageValue={currentPage}
                    setPage={setCurrentPage}
                  />
                </div>
              </div>
            )}
          </Card.Body>
        </Card>
      </Col>
      {loader && <Spinner />}
    </div>
  );
}
