import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { toast, ToastContainer } from "react-toastify";
import { changePassword } from "../../../services/AuthService";
import "react-toastify/dist/ReactToastify.css";

export default function ChangePassword({ password, onHide }) {
  let errorsObj = { oldPassword: "", newPassword: "", confirmPassword: "" };
  const [errors, setErrors] = useState(errorsObj);
  const [apiError, setApiError] = useState();
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const notifyTopRight = (success) => {
    toast.success(`✅ ${success}`, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
  };
  const notifyError = (error) => {
    toast.error(`❌${error}`, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const handleSubmit = async () => {
    let error = false;
    const errorObj = { ...errorsObj };
    if (oldPassword === "") {
      errorObj.oldPassword = "Old Password is Required";
      error = true;
    }
    if (newPassword === "") {
      errorObj.newPassword = "Password is Required";
      error = true;
    }
    if (confirmPassword === "") {
      errorObj.confirmPassword = "Password is Required";
      error = true;
    }
    if (oldPassword == newPassword && oldPassword !== "") {
      errorObj.newPassword = "Please create new password";
      error = true;
    }
    if (newPassword !== confirmPassword) {
      errorObj.confirmPassword = "Password not matched";
      error = true;
    }
    setErrors(errorObj);
    if (error) {
      return;
    }
    try {
      const response = await changePassword({
        oldPassword: oldPassword,
        newPassword: newPassword,
      });
      notifyTopRight(response.data.message);
      setOldPassword("");
      setNewPassword("");
      setConfirmPassword("");
      onHide();
    } catch (error) {
      notifyError(error.response.message);
    }
  };
  return (
    <div>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <Modal className="modal fade" onHide={onHide} show={password} centered>
        <div className="" role="document">
          <div className="">
            <form>
              <div className="modal-header">
                <h4 className="modal-title fs-20">Change Password</h4>
              </div>
              <div className="modal-body">
                <i
                  className="flaticon-cancel-12 close"
                  data-dismiss="modal"
                ></i>
                <div className="add-contact-box">
                  <div className="add-contact-content">
                    {apiError && (
                      <div
                        role="alert"
                        className="fade alert-dismissible fade show alert alert-danger show"
                      >
                        {apiError}
                      </div>
                    )}
                    <div className="form-group mb-3">
                      <label className="text-black font-w500">
                        Old Password
                      </label>
                      <div className="contact-name">
                        <input
                          type="password"
                          className="form-control"
                          autocomplete="off"
                          name="name"
                          value={oldPassword}
                          onChange={(e) => setOldPassword(e.target.value)}
                          placeholder="Enter your old password"
                        />
                        <span className="validation-text"></span>
                        {errors.oldPassword && (
                          <div className="text-danger fs-12">
                            {errors.oldPassword}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="form-group mb-3">
                      <label className="text-black font-w500">
                        New Password
                      </label>
                      <div className="contact-name">
                        <input
                          type="password"
                          className="form-control"
                          autocomplete="off"
                          name="department"
                          value={newPassword}
                          onChange={(e) => setNewPassword(e.target.value)}
                          placeholder="Enter new password"
                        />
                        <span className="validation-text"></span>
                        {errors.newPassword && (
                          <div className="text-danger fs-12">
                            {errors.newPassword}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="form-group mb-3">
                      <label className="text-black font-w500">
                        Confirm New Password
                      </label>
                      <div className="contact-name">
                        <input
                          type="password"
                          className="form-control"
                          autocomplete="off"
                          name="department"
                          value={confirmPassword}
                          onChange={(e) => setConfirmPassword(e.target.value)}
                          placeholder="Re-enter new password"
                        />
                        <span className="validation-text"></span>
                        {errors.confirmPassword && (
                          <div className="text-danger fs-12">
                            {errors.confirmPassword}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-color text-white"
                  onClick={handleSubmit}
                >
                  Submit
                </button>
                <button
                  type="button"
                  onClick={() => onHide()}
                  className="btn btn-secondary light"
                >
                  {" "}
                  <i className="flaticon-delete-1"></i> Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
      </Modal>
    </div>
  );
}
