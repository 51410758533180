import React, { useEffect, useState } from "react";
import { Card, Col, Table } from "react-bootstrap";
import PageTitle from "../layouts/PageTitle";
import Spinner from "../components/common/Spinner";
import Pagination from "../components/common/Pagination";
import { toast, ToastContainer } from "react-toastify";
import AddInteriorD from "../components/Modal/AddInteriorD";
import { actionOnInquery, getInqueryList } from "../../services/AuthService";
import CollapseTableRow from "../components/Table/CollapseTableRow";

export default function ApproveInquiry(props) {
  const svg1 = (
    <svg width="20px" height="20px" viewBox="0 0 24 24" version="1.1">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <rect x="0" y="0" width="24" height="24"></rect>
        <circle fill="#000000" cx="5" cy="12" r="2"></circle>
        <circle fill="#000000" cx="12" cy="12" r="2"></circle>
        <circle fill="#000000" cx="19" cy="12" r="2"></circle>
      </g>
    </svg>
  );
  const notifyTopRight = (success) => {
    toast.success(`✅ ${success}`, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
  };
  const notifyError = (error) => {
    toast.error(`❌${error}`, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };
  const [loader, setLoader] = useState(false);
  const [users, setUsers] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const limit = 10;
  const [listLength, setListlenght] = useState("");
  const [addModal, setAddModal] = useState(false);

  function getTableData() {
    setLoader(true);
    getInqueryList(currentPage, limit)
      .then((response) => {
        setUsers(response.data.data?.inqueryList);
        const total = response.data.data.total;
        setLoader(false);
        setPageCount(Math.ceil(total / limit));
        setListlenght(response.data.data.total);
      })
      .catch((error) => {
        if (error?.response?.data?.statusCode === 401) {
          localStorage.removeItem("taldDetails");
          props.history.push("/login");
        }
      });
  }

  function onAction(userId, isReject) {
    setLoader(true);
    actionOnInquery(userId, isReject)
      .then((response) => {
        if (isReject === "Accept") {
          notifyTopRight("Accept Successfully!");
        } else {
          notifyTopRight("Reject Successfully!");
        }
        setLoader(false);
        getTableData();
      })
      .catch((error) => {
        setLoader(false);
        notifyError(error.response?.data?.message);
        if (error.response?.data?.statusCode === 401) {
          localStorage.removeItem("taldDetails");
          props.history.push("/login");
        }
      });
  }

  useEffect(() => {
    getTableData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  return (
    <div>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <PageTitle activeMenu="Inquiry List" motherMenu="Inquiry" />
      <Col>
        <Card>
          <Card.Body>
            <Table responsive>
              <thead style={{ color: "black" }}>
                <tr>
                  <th>
                    <strong>DATE/TIME</strong>
                  </th>
                  <th>
                    <strong>INTERIOR DESIGNER</strong>
                  </th>
                  <th>
                    <strong>EMAIL</strong>
                  </th>
                  <th>
                    <strong>STATUS</strong>
                  </th>
                </tr>
              </thead>
              <tbody className="border-bottom border-secondary">
                {users?.map((item) => (
                  <CollapseTableRow
                    item={item}
                    onAction={onAction}
                    svg1={svg1}
                  />
                ))}
              </tbody>
            </Table>
            {users?.length === 0 && !loader ? (
              <div className="justify-content-center d-flex my-5 ">
                Sorry, Data Not Found!
              </div>
            ) : (
              ""
            )}
            {users?.length === 0 ? (
              ""
            ) : (
              <div className="">
                <div className="d-flex justify-content-between align-items-center">
                  <div className="dataTables_info">
                    Showing {currentPage * limit + 1} to{" "}
                    {listLength > (currentPage + 1) * limit
                      ? (currentPage + 1) * limit
                      : listLength}{" "}
                    of {listLength} entries
                  </div>
                  <Pagination
                    pageCount={pageCount}
                    pageValue={currentPage}
                    setPage={setCurrentPage}
                  />
                </div>
              </div>
            )}
          </Card.Body>
        </Card>
      </Col>
      <AddInteriorD
        show={addModal}
        table={getTableData}
        onHide={() => setAddModal(false)}
      />
      {loader && <Spinner />}
    </div>
  );
}
