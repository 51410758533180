import React, { useEffect, useState } from "react";
import { Badge, Card, Col, Dropdown, Table } from "react-bootstrap";
import { getAllUsers } from "../../services/AuthService";
import PageTitle from "../layouts/PageTitle";
import Spinner from "../components/common/Spinner";
import Pagination from "../components/common/Pagination";

export default function ProjectInquiry(props) {
  const svg1 = (
    <svg width="20px" height="20px" viewBox="0 0 24 24" version="1.1">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <rect x="0" y="0" width="24" height="24"></rect>
        <circle fill="#000000" cx="5" cy="12" r="2"></circle>
        <circle fill="#000000" cx="12" cy="12" r="2"></circle>
        <circle fill="#000000" cx="19" cy="12" r="2"></circle>
      </g>
    </svg>
  );
  const [loader, setLoader] = useState(false);
  const [users, setUsers] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [apiError, setApiError] = useState("");
  const [search, setSearch] = useState("");
  const limit = 10;
  function getTableData() {
    setLoader(true);
    getAllUsers(currentPage, limit, search)
      .then((response) => {
        // setUsers(response.data.data);
        const total = response.data.data.total;
        setLoader(false);
        setPageCount(Math.ceil(total / limit));
      })
      .catch((error) => {
        if (error.response?.data?.statusCode === 401) {
          localStorage.clear("taldDetails");
          props.history.push("/login");
        }
      });
  }

  useEffect(() => {
    getTableData();
  }, [currentPage]);
  return (
    <div>
      <PageTitle activeMenu="Inquiry List" motherMenu="Project Inquiry" />
   
        <Card>
          <Card.Body>
            <Table responsive>
              <thead style={{ color: "black" }}>
                <tr>
                  <th>
                    <strong>DATE/TIME</strong>
                  </th>
                  <th>
                    <strong>INTERIOR DESIGNER</strong>
                  </th>
                  <th>
                    <strong>CLIENT</strong>
                  </th>
                  <th>
                    <strong>STATUS</strong>
                  </th>
                  <th>
                    <strong>ACTION</strong>
                  </th>
                </tr>
              </thead>
              <tbody>
                {users?.map((item) => (
                  <tr>
                    <td>26/07/2023</td>
                    <td
                      onClick={() => props.history.push("/client-profile")}
                      style={{ cursor: "pointer" }}
                    >
                      XYZ
                    </td>
                    <td
                      onClick={() => props.history.push("/client-profile")}
                      style={{ cursor: "pointer" }}
                    >
                      XYZ
                    </td>
                    <td>
                      <Badge variant="success light ">Upcoming</Badge>
                    </td>
                    <td>
                      <Dropdown>
                        <Dropdown.Toggle
                          variant="secondary light"
                          className="light sharp btn btn-secondary i-false"
                        >
                          {svg1}
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          <Dropdown.Item>View Details</Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
            {users?.length === 0 && !loader ? (
              <div className="justify-content-center d-flex my-5 ">
                Sorry, Data Not Found!
              </div>
            ) : (
              ""
            )}
            {/* <Pagination
              pageCount={pageCount}
              pageValue={currentPage}
              setPage={setCurrentPage}
            /> */}
          </Card.Body>
        </Card>
      
      {loader && <Spinner />}
    </div>
  );
}
