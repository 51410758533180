import React, { useEffect, useState } from "react";
import { Badge, Card, Col, Dropdown, Table } from "react-bootstrap";
import { getAllUsers, getVirtualConsultant } from "../../services/AuthService";
import PageTitle from "../layouts/PageTitle";
import Spinner from "../components/common/Spinner";
import Pagination from "../components/common/Pagination";
import moment from "moment";

export default function VirtualConsultant(props) {
  const svg1 = (
    <svg width="20px" height="20px" viewBox="0 0 24 24" version="1.1">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <rect x="0" y="0" width="24" height="24"></rect>
        <circle fill="#000000" cx="5" cy="12" r="2"></circle>
        <circle fill="#000000" cx="12" cy="12" r="2"></circle>
        <circle fill="#000000" cx="19" cy="12" r="2"></circle>
      </g>
    </svg>
  );
  const [loader, setLoader] = useState(false);
  const [users, setUsers] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const limit = 10;
  const [listLength, setListlenght] = useState("");
  function getTableData() {
    setLoader(true);
    getVirtualConsultant(currentPage, limit)
      .then((response) => {
        setUsers(response.data.data?.consultations);
        const total = response.data.data.total;
        setLoader(false);
        setPageCount(Math.ceil(total / limit));
        setListlenght(response.data.data.total);
      })
      .catch((error) => {
        if (error.response.data.statusCode === 401) {
          localStorage.clear("taldDetails");
          props.history.push("/login");
        }
      });
  }
  useEffect(() => {
    getTableData();
  }, [currentPage]);
  return (
    <div>
      <PageTitle
        activeMenu="Virtual Consultant List"
        motherMenu="Virtual Consultant"
      />
      <Col>
        <Card>
          <Card.Body>
            <Table responsive>
              <thead style={{ color: "black" }}>
                <tr>
                  <th>
                    <strong>DATE/TIME</strong>
                  </th>
                  <th>
                    <strong>INTERIOR DESIGNER</strong>
                  </th>
                  <th>
                    <strong>CLIENT</strong>
                  </th>
                  <th>
                    <strong>STATUS</strong>
                  </th>
                </tr>
              </thead>
              <tbody>
                {users?.map((item) => (
                  <tr>
                    <td>{moment(item.createdAt).format("MM/DD/YYYY")}</td>
                    <td>{item?.designer?.name}</td>
                    <td>{item?.user?.name}</td>

                    <td>
                      {item.isReject && !item.isVerify && (
                        <Badge variant="danger light "> Reject</Badge>
                      )}

                      {!item.isReject && !item.isVerify && (
                        <Badge variant="warning light "> Pending</Badge>
                      )}
                      {!item.isReject && item.isVerify && (
                        <Badge variant="success light "> Accept</Badge>
                      )}
                    </td>
                    <td></td>
                  </tr>
                ))}
              </tbody>
            </Table>
            {users?.length === 0 && !loader ? (
              <div className="justify-content-center d-flex my-5 ">
                Sorry, Data Not Found!
              </div>
            ) : (
              ""
            )}
            {users?.length === 0 ? (
              ""
            ) : (
              <div className="">
                <div className="d-flex justify-content-between align-items-center">
                  <div className="dataTables_info">
                    Showing {currentPage * limit + 1} to{" "}
                    {listLength > (currentPage + 1) * limit
                      ? (currentPage + 1) * limit
                      : listLength}{" "}
                    of {listLength} entries
                  </div>
                  <Pagination
                    pageCount={pageCount}
                    pageValue={currentPage}
                    setPage={setCurrentPage}
                  />
                </div>
              </div>
            )}
          </Card.Body>
        </Card>
      </Col>
      {loader && <Spinner />}
    </div>
  );
}
