import React, { useEffect, useState } from "react";
import { Badge, Card, Dropdown, Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import { getAllUsers, getClientDetails } from "../../services/AuthService";
import PageTitle from "../layouts/PageTitle";
import Spinner from "../components/common/Spinner";
import { connect } from "react-redux";
import { useLocation } from "react-router-dom";
import moment from "moment";

function ClientProfile(props) {
  const [loader, setLoader] = useState(false);
  const [userDetails, setUserDetails] = useState({});
  const location = useLocation();

  // Retrieve the state from the location object
  const userId = location.state;

  function getClientProfileDetails() {
    setLoader(true);
    getClientDetails("6548b693d0bb6e2486dbe9ea")
      .then((response) => {
        setUserDetails(response?.data?.data);
        // const total = response.data.data.total;
        setLoader(false);
        // setPageCount(Math.ceil(total / limit));
        // setListlenght(response.data.data.total);

      })
      .catch((error) => {
        setLoader(false);
        if (error.response?.data?.statusCode === 401) {
          localStorage.clear("taldDetails");
          props.history.push("/login");
        }
      });
  }
  useEffect(() => {
    getClientProfileDetails();
  }, [userId]);
  return (
    <div>
      <div className="page-titles">
        <h4>Clients Profile</h4>
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="client-management">Client Management</Link>
          </li>
          <li className="breadcrumb-item active">
            <Link>Clients Profile</Link>
          </li>
        </ol>
      </div>
      <div className="row">
        <div className="col-xl-3 col-xxl-4 col-lg-6 col-sm-6">
          <div className="widget-stat card bg-theme">
            <div className="card-body p-4">
              <div className="media">
                <span className="mr-3">
                  <i className="flaticon-381-user-7"></i>
                </span>
                <div className="media-body text-black text-right">
                  <p className="mb-1">Total Virtual consultation</p>
                  <h4 className="text-black">
                    {userDetails?.totalVirtualConsultation}
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-3 col-xxl-4 col-lg-6 col-sm-6">
          <div className="widget-stat card bg-theme">
            <div className="card-body p-4">
              <div className="media">
                <span className="mr-3">
                  <i className="flaticon-381-user-7"></i>
                </span>
                <div className="media-body text-black text-right">
                  <p className="mb-1">Total Project Inquiry</p>
                  <h4 className="text-black">
                    {userDetails?.totalProjectInqueries}
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Card className="col-xl-6 col-xxl-6 col-lg-6 col-sm-3">
          <Card.Body className="d-block">
            <div className="d-flex justify-content-between align-items-center ">
              <div className="">
                <p className="text-black">Name</p>
                <p className="text-black">Email</p>
                {/* <p className="text-black">Instagram</p> */}
              </div>
              <div className="">
                <p className="">{userDetails?.user?.name}</p>
                <p className="">{userDetails?.user?.email}</p>
                {/* <p className="text-start">test@gmail.com</p> */}
              </div>
            </div>
          </Card.Body>
        </Card>
      </div>
      <Card className="details-font">
        <Card.Header>
          <h2>Projects</h2>
        </Card.Header>
        <Card.Body>
          {userDetails?.projects?.map((item, i) => (
            <>
              <div
                key={i}
                className="d-flex justify-content-between align-items-center bg-white p-4 mb-4 rounded"
              >
                <div>
                  <h3>{item?.projectName}</h3>
                  <h5 className="pb-3">
                    CREATED {moment(item.createdAt).format("MM/DD/YYYY")}
                  </h5>
                  <p className="mb-0">{item?.address}</p>
                  <p className="mt-0">Budget: ${item?.projectFund}</p>
                </div>
                <div>
                  <button
                    className="btn btn-outline-secondary"
                    onClick={() => props.history.push("/project-details")}
                  >
                    View Details
                  </button>
                </div>
              </div>
            </>
          ))}

          {/* <div className="d-flex justify-content-between align-items-center bg-white p-4 mb-4 rounded">
            <div>
              <h3>Kitchen Renovation</h3>
              <h5 className="pb-3">CREATED OCTOBER 14, 2023</h5>
              <p className="mb-0">Location: Manhattan City New York</p>
              <p className="mt-0">Budget: $400</p>
            </div>
            <div>
              <button
                className="btn btn-outline-secondary"
                onClick={() => props.history.push("/project-details")}
              >
                View Details
              </button>
            </div>
          </div> */}
        </Card.Body>
      </Card>
      {loader && <Spinner />}
    </div>
  );
}

export default ClientProfile;
