import React, { useEffect, useState } from "react";
import {
  exchangeCodeForToken,
  pinterestTokenValidation,
} from "../../services/AuthService";
import { toast } from "react-toastify";

function Integrations() {
  const [authCode, setAuthCode] = useState("");
  const [tokenValidated, setTokenValidated] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const initializeIntegration = async () => {
      setLoading(true);
      try {
        const urlParams = new URLSearchParams(window.location.search);
        const code = urlParams.get("code");
        if (code) {
          setAuthCode(code);
          window.history.pushState(
            {},
            document.title,
            window.location.pathname
          );
        } else if (!tokenValidated) {
          const response = await pinterestTokenValidation();
          const isValidated = response.data.message === "Success";
          setTokenValidated(isValidated);
          toast.success(`✅ Token Validated`, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
        }
      } catch (err) {
        toast.error("❌ " + err.response.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      } finally {
        setLoading(false);
      }
    };
    initializeIntegration();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const exchangeToken = async () => {
      if (!authCode) return;
      setLoading(true);
      try {
        await exchangeCodeForToken(authCode);
        setTokenValidated(true);
        toast.success(`✅ Token Validated`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
        setAuthCode("");
      } catch (err) {
        console.error(err);
        toast.error(
          "❌ " +
            JSON.parse(err.response.data.message.split("data: ")[1]).message,
          {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          }
        );
      } finally {
        setLoading(false);
      }
    };

    exchangeToken();
  }, [authCode]);

  const redirectToPinterest = () => {
    const clientId = process.env.REACT_APP_PINTEREST_CLIENT_ID;
    const redirectUri = process.env.REACT_APP_PINTEREST_REDIRECT_URL;
    const scope =
      "boards:read,pins:read,boards:write,pins:write,ads:write,ads:read,user_accounts:read,user_accounts:write";

    if (!clientId || !redirectUri) {
      console.error("Missing Pinterest OAuth environment variables");
      return;
    }

    const baseUrl = `https://www.pinterest.com/oauth/?client_id=${clientId}&redirect_uri=${encodeURIComponent(
      redirectUri
    )}&response_type=code&scope=${scope}`;
    window.location.href = baseUrl;
  };

  const styles = {
    container: {
      border: "1px solid #ccc",
      borderRadius: "5px",
      padding: "20px",
      margin: "20px",
      width: "max-content",
    },
    header: {
      fontSize: "larger",
      fontWeight: "bold",
      marginBottom: "15px",
    },
    success: {
      color: "green",
      fontSize: "24px",
      display: "flex",
      alignItems: "center",
      gap: "20px",
    },
    button: {
      padding: "10px 20px",
      cursor: "pointer",
      fontSize: "14px",
    },
    error: {
      color: "red",
      marginTop: "10px",
    },
  };

  return (
    <div style={styles.container}>
      <div style={styles.header}>
        {tokenValidated
          ? "Pinterest Integration Successful"
          : "Connect Pinterest Here"}
      </div>
      {loading ? (
        <div>Loading...</div>
      ) : tokenValidated ? (
        <div style={styles.success}>
          ✔︎
          <button style={styles.button} onClick={redirectToPinterest}>
            Revalidate
          </button>
        </div>
      ) : (
        <>
          <button style={styles.button} onClick={redirectToPinterest}>
            Validate
          </button>
        </>
      )}
    </div>
  );
}

export default Integrations;
